<template>
    <v-card :elevation="5">
        <v-card-title>
            Promotions
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="promotions"
            :search="search"
            :loading="loading"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        :to="{ name: 'promotions.create' }"
                    >
                        New Promotion
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="details(item)"
                >
                    mdi-eye
                </v-icon>
                <v-icon
                    small
                    class="mr-2"
                    @click="update(item)"
                    v-if="['active', 'upcoming'].includes(item.status)"
                >
                    mdi-wrench
                </v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>

export default {
  data () {
    return {
      promotions: [],
      loading: true,
      search: '',
      headers: [
        {
          value: 'promotion_type',
          text: 'Promotion Type'
        },
        {
          value: 'status',
          text: 'Status'
        },
        {
          value: 'title',
          text: 'Promotion Code'
        },
        {
          value: 'priority',
          text: 'Priority'
        },
        {
          value: 'description',
          text: 'Description'
        },
        {
          value: 'begins_at',
          text: 'Starting Date'
        },
        {
          value: 'expires_at',
          text: 'Expiration Date'
        },
        {
          value: 'win_cap',
          text: 'Win Cap €'
        },
        {
          value: 'promotion_cap',
          text: 'Promotion Cap €'
        },
        {
          value: 'promotion_amount_used',
          text: 'Promotion Amount Used €'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },

  mounted () {
    this.$axios.get('api/promotions').then(response => {
      this.promotions = response.data
      this.loading = false
    })
  },

  methods: {
    details (item) {
      this.$router.push({
        name: 'promotions.show',
        params: { promotion: item.id }
      })
    },
    update (item) {
      this.$router.push({
        name: 'promotions.update',
        params: { promotion: item.id }
      })
    }
  }
}
</script>
